import styles from './styles.module.scss'
interface IProps {
    title: string
    title2?: string
    children?: JSX.Element | undefined
}

const Title = ({ title, title2, children }: IProps) => {
    return (
        <div className={`is-flex  is-justify-content-space-between mb-[2rem] ${styles.title}`}>
            <div className=" text-xs has-text-weight-bold flex justify-center">
                {title}
                {title2 && (
                    <>
                        <span className="mx-2 has-text-grey-light text-[.5rem] ">|</span>
                        <span className="has-text-grey-light"> {title2}</span>
                    </>
                )}
                {children?.length > 0 && children[1] && <div className={styles.details}>{children[1]}</div>}
            </div>
            {children?.length > 0 ? children[0] : children}
        </div>
    )
}

export default Title
