import Link from 'next/link'
import styles from './styles.module.scss'
import SearchBar from '../Search'
import PackageSVG from 'shared/images/package.svg'
import TruckSVG from 'shared/images/truck-nav.svg'
import CustomersSVG from 'shared/images/users.svg'
import CoffeeSVG from 'shared/images/coffee.svg'
import ClipboardSVG from 'shared/images/clipboard.svg'
import HomeSVG from 'shared/images/home.svg'
import CrosshairSVG from 'shared/images/crosshair.svg'
import { useRouter } from 'next/router'

const Navbar = () => {
    const router = useRouter()

    const currentMonth: number = `${new Date().getFullYear()}${new Date().getMonth() + 1}` as unknown as number

    return (
        <nav className={styles.nav}>
            <SearchBar />
            <ul>
                <li>
                    <Link href="/">
                        {/* <FontAwesomeIcon icon={faCaravan} size="lg" /> */}
                        <HomeSVG />
                        Overview
                    </Link>
                </li>
                <li>
                    <Link href="/analytics">
                        {/* <FontAwesomeIcon icon={faCaravan} size="lg" /> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-chart-line"
                        >
                            <path d="M3 3v16a2 2 0 0 0 2 2h16" />
                            <path d="m19 9-5 5-4-4-3 3" />
                        </svg>
                        Analytics
                    </Link>
                </li>
            </ul>
            <div className={styles.menuTitle}>Procurement</div>
            <ul>
                <li className={router.pathname == '/products' ? styles.active : ''}>
                    <Link href={`/products`} as={`/products`}>
                        {/* <FontAwesomeIcon icon={faCalculator} size="lg" /> */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-tag"
                        >
                            <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                            <line x1="7" y1="7" x2="7.01" y2="7"></line>
                        </svg>
                        Products
                    </Link>
                </li>
                <li className={router.pathname == '/purchase-orders' ? styles.active : ''}>
                    <Link href="/purchase-orders" as="/purchase-orders">
                        {/* <FontAwesomeIcon icon={faPiggyBank} size="lg" />
                         */}
                        <ClipboardSVG />
                        Purchase orders
                    </Link>
                </li>
                <li className={router.pathname == '/suppliers' ? styles.active : ''}>
                    <Link href={`/suppliers`} as={`/suppliers`}>
                        {/* <FontAwesomeIcon icon={faCalculator} size="lg" /> */}
                        <CoffeeSVG />
                        Suppliers
                    </Link>
                </li>
                <li className={router.pathname == '/transfers' ? styles.active : ''}>
                    <Link href={`/transfers`} as={`/transfers`}>
                        {/* <FontAwesomeIcon icon={faCalculator} size="lg" /> */}
                        <CoffeeSVG />
                        Transfers
                    </Link>
                </li>
            </ul>
            <div className={styles.menuTitle}>Sales</div>
            <ul>
                <li className={router.pathname == '/offers' ? styles.active : ''}>
                    <Link href={`/offers`} as={`/offers`}>
                        {/* <FontAwesomeIcon icon={faCalculator} size="lg" /> */}
                        <CrosshairSVG />
                        Offers
                    </Link>
                </li>
                <li className={router.pathname == '/orders' ? styles.active : ''}>
                    <Link href="/orders" as="/orders">
                        {/* <FontAwesomeIcon icon={faMoneyCheck} size="lg" /> */}
                        <PackageSVG />
                        Orders
                    </Link>
                </li>
                <li className={router.pathname == '/customers' ? styles.active : ''}>
                    <Link href={`/customers`} as={`/customers`}>
                        {/* <FontAwesomeIcon icon={faChartBar} size="lg" /> */}
                        <CustomersSVG />
                        Customers
                    </Link>
                </li>
            </ul>
            <div className={styles.menuTitle}>Fulfillment</div>

            <ul>
                <li className={router.pathname == '/shipments' ? styles.active : ''}>
                    <Link href={`/shipments`} as={`/shipments`}>
                        {/* <FontAwesomeIcon icon={faChartBar} size="lg" /> */}
                        <TruckSVG />
                        Shipments
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar
