import { useState } from 'react'
import styles from './styles.module.scss'
import Header from './Header'

const Layout = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    const [isMenuShown, setMenuShown] = useState(false)
    return (
        <>
            <div
                className="is-hidden-tablet"
                onClick={() => {
                    setMenuShown(true)
                }}
            >
                ---
                {/* <FontAwesomeIcon
                    icon={faBars}
                    size="lg"
                    className="is-color-green mt-4 ml-4"
                /> */}
            </div>
            <div className="is-flex">
                <Header isMenuShown={isMenuShown} setMenuShown={setMenuShown} />
                <main className={styles.main}>
                    <div className="container " style={{ width: '100%' }}>
                        {children}
                    </div>
                </main>
            </div>
        </>
    )
}

export default Layout
