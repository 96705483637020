import { useEffect, useRef } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, Hits, Configure, useSearchBox, useInstantSearch, Index } from 'react-instantsearch'
import Link from 'next/link'
import styles from './styles.module.scss'
import PackageSVG from 'shared/images/package.svg'
import TruckSVG from 'shared/images/truck-nav.svg'
import CustomersSVG from 'shared/images/users.svg'
import CoffeeSVG from 'shared/images/coffee.svg'
import ClipboardSVG from 'shared/images/clipboard.svg'
import CrosshairSVG from 'shared/images/crosshair.svg'
import { getEnvironmentVariable } from 'shared/utils/getEnvironmentVariable'
import { PUBLIC_KEYS_MAP } from './publicKeysMap'
import { useUser } from 'modules/user/context/userContext'

const searchContainerPrefix = getEnvironmentVariable('algoliaIndexPrefix')

//88d75973aa7b2d0d82ba3bf17f3634b3

function HitsOrders({ hit }) {
    return (
        <>
            <Link href={`/orders/${hit.id}`} className={styles.hit}>
                {hit.name}
            </Link>
            <div className={`${styles.menu} is-flex`}>{hit.customer?.id && <Link href={`/customers/${hit.customer?.id}`}>{hit.customer?.name}</Link>}</div>
        </>
    )
}

function HitsPurchaseOrder({ hit }) {
    return (
        <>
            <Link href={`/purchase-orders/${hit.id}`} className={styles.hit}>
                #{hit.orderNumber}
            </Link>
            <div className={`${styles.menu} is-flex`}>
                <Link href={`/suppliers/${hit.supplier.id}`}>{hit.supplier.name}</Link>
                {/* <div className={styles.divider}>|</div> */}
                {/* <Link href={`/brands/${hit.brand.id}`}>{hit.brand.name}</Link> */}
            </div>
        </>
    )
}

function HitsBrands({ hit }) {
    return (
        <div>
            <Link href={`/brands/${hit.id}`} className={styles.hit}>
                {hit.name}
            </Link>
            <div className={`${styles.menu} is-flex`}>
                <Link href={`/brands/${hit.id}/incoming`}>Incoming</Link>
            </div>
        </div>
    )
}

function HitsProducts({ hit }) {
    return (
        <>
            {' '}
            <Link href={`/products/${hit.productId}`} className={styles.hit}>
                {hit.productName} - {hit.variantName}
            </Link>
            <div className={`${styles.menu} is-flex`}>
                <Link href={`/brands/${hit.brand?.id}`}>{hit.brand?.name}</Link>
                <div className={styles.divider}>|</div>
                <Link href={`/products/${hit.productId}`}>{hit.sku}</Link>
            </div>
        </>
    )
}

function HitsSuppliers({ hit }) {
    return (
        <>
            <Link href={`/suppliers/${hit.id}`} className={styles.hit}>
                {hit.companyName}
            </Link>
            <div className={`${styles.menu} is-flex`}>
                <Link href={`/suppliers/${hit.id}/incoming`} className={styles.hit}>
                    Incoming
                </Link>
            </div>
        </>
    )
}

function HitsCustomers({ hit }) {
    return (
        <Link href={`/customers/${hit.id}`} className={styles.hit}>
            {hit.name}
        </Link>
    )
}

function EmptyQueryBoundary({ children, fallback }) {
    const { indexUiState, results, uiState } = useInstantSearch()

    // if (!indexUiState.query) {
    //     return fallback
    // }

    return children
}

const queryHook = (query, search) => {
    search(query)
}

const SearchBar = ({ isSearchOpen, setSearchOpen }) => {
    const { user } = useUser()
    const publicKey = PUBLIC_KEYS_MAP[user?.company?.name]
    const searchClient = algoliasearch('YSQ4VBZDTL', publicKey)

    const inputElement = useRef()
    useEffect(() => {
        setTimeout(() => {
            inputElement.current?.focus()
        }, 200)
    }, [isSearchOpen])

    function CustomSearchBox(props) {
        const { query, refine, clear, isSearchStalled } = useSearchBox({
            queryHook,
        })

        const handleChange = (e) => {
            refine(e.target.value)
        }

        return (
            <>
                <input type="search" className={styles.searchBar} placeholder="Search" onChange={handleChange} ref={inputElement} />
            </>
        )
    }

    const closeSearch = (e) => {
        if (e.target === e.currentTarget) {
            console.log('parent clicked')
            // 👇 your logic here
            setSearchOpen(false)
        }
    }

    return (
        <>
            <InstantSearch searchClient={searchClient} indexName={`${searchContainerPrefix}_orders`}>
                <EmptyQueryBoundary fallback={null}>
                    <div style={{ display: isSearchOpen ? 'block' : 'none' }}>
                        <>
                            <div className={styles.searchOverlayNavbar} onClick={closeSearch}>
                                <CustomSearchBox />
                            </div>

                            <div className={`${styles.searchOverlay} `}>
                                <>
                                    <div className={`${styles.results} has-border-bottom   is-flex is-justify-content-space-between`}>
                                        <div className={styles.resultsColumn}>
                                            <h2>
                                                <ClipboardSVG />
                                                Purchase orders
                                            </h2>
                                            <div className={styles.searchContent}>
                                                <Index indexName={`${searchContainerPrefix}_purchase_orders`}>
                                                    <Configure hitsPerPage={8} />
                                                    <Hits hitComponent={HitsPurchaseOrder} />
                                                </Index>
                                            </div>
                                        </div>
                                        <div className={styles.resultsColumn}>
                                            <h2>
                                                <CoffeeSVG />
                                                Suppliers
                                            </h2>
                                            <div className={styles.searchContent}>
                                                <Index indexName={`${searchContainerPrefix}_suppliers`}>
                                                    <Configure hitsPerPage={4} />
                                                    <Hits hitComponent={HitsSuppliers} />
                                                </Index>
                                            </div>
                                        </div>
                                        <div className={styles.resultsColumn}>
                                            <h2>
                                                <TruckSVG />
                                                Orders
                                            </h2>
                                            <div className={styles.searchContent}>
                                                <Index indexName={`${searchContainerPrefix}_orders`}>
                                                    <Configure hitsPerPage={8} />
                                                    <Hits hitComponent={HitsOrders} />
                                                </Index>
                                            </div>
                                        </div>
                                        <div className={styles.resultsColumn}>
                                            <h2>
                                                <PackageSVG />
                                                Products
                                            </h2>
                                            <div className={styles.searchContent}>
                                                <Index indexName={`${searchContainerPrefix}_products`}>
                                                    <Configure hitsPerPage={6} />
                                                    <Hits hitComponent={HitsProducts} />
                                                </Index>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${styles.results} mt-4 is-flex `}>
                                        <div className={styles.resultsColumn}>
                                            <h2>
                                                <CustomersSVG />
                                                Customers
                                            </h2>
                                            <div className={styles.searchContent}>
                                                <Index indexName={`${searchContainerPrefix}_customers`}>
                                                    <Configure hitsPerPage={8} />
                                                    <Hits hitComponent={HitsCustomers} />
                                                </Index>
                                            </div>
                                        </div>
                                        <div className={styles.resultsColumn}>
                                            <h2>
                                                <CrosshairSVG />
                                                Brands
                                            </h2>
                                            <div className={styles.searchContent}>
                                                <Index indexName={`${searchContainerPrefix}_brands`}>
                                                    <Configure hitsPerPage={8} />
                                                    <Hits hitComponent={HitsBrands} />
                                                </Index>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            </div>
                        </>
                    </div>
                </EmptyQueryBoundary>
            </InstantSearch>
        </>
    )
}

export default SearchBar
