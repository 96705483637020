import { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import Navbar from './Navbar'
import Link from 'shared/components/Link'
import { useUser } from 'modules/user/context/userContext'
import { useSpring, animated } from 'react-spring'
import isMobile from 'ismobilejs'
import CloseIcon from 'shared/images/close.svg'
import Fade from 'shared/components/Fade'
import { useAuth0 } from '@auth0/auth0-react'

type TProps = {
    isMenuShown: boolean
    setMenuShown: (state: boolean) => void
}

const Header = ({ isMenuShown, setMenuShown }: TProps) => {
    const { logout } = useAuth0()
    const { user, company } = useUser()
    const [isMobileView, setMobileView] = useState<boolean>(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [isSettingsMenuOpen, setSettingsMenuOpen] = useState(false)
    const open = Boolean(anchorEl)

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const toggleSettingsMenu = () => {
        setSettingsMenuOpen((isSettingsMenuOpen) => !isSettingsMenuOpen)
    }

    const props = useSpring({
        left: isMenuShown || !isMobileView ? 0 : -600,
    })

    useEffect(() => {
        setMobileView(isMobile(window.navigator).any)
    }, [])

    return (
        <animated.div style={props} className={`${styles.header}`}>
            <header className={`is-flex  is-flex-direction-column`} style={{ height: '100vh' }}>
                <div className={`${styles.closeHeader} is-hidden-tablet`}>
                    <CloseIcon
                        className={styles.closeIcon}
                        onClick={() => {
                            setMenuShown(false)
                        }}
                    />
                </div>
                <div className={`${styles.logo} `}>
                    <Link href="/" className="is-flex is-align-items-center">
                        <span className="ml-3  text-xs" style={{ fontWeight: 900 }}>
                            Trade Central
                        </span>
                    </Link>
                </div>
                <div className="">
                    <Navbar />
                </div>
                <div className="is-flex is-relative" style={{ marginTop: 'auto' }}>
                    <Fade show={isSettingsMenuOpen}>
                        <div className={styles.settingsPopup}>
                            <ul>
                                <li>
                                    <Link href="/account/settings">Settings</Link>
                                </li>
                                <li>
                                    <Link href="/account/profile">Profile</Link>
                                </li>
                                <li>
                                    <span
                                        onClick={() =>
                                            logout({
                                                returnTo: window.location.origin,
                                            })
                                        }
                                    >
                                        Log out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </Fade>
                    {user && (
                        <>
                            <div className="is-clickable p-2 rounded-full" onClick={toggleSettingsMenu} style={{ backgroundColor: '#8A8DBA' }}>
                                {user.initials}
                            </div>
                        </>
                    )}
                </div>
            </header>
        </animated.div>
    )
}

export default Header
