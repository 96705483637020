import { Role } from '../types/role'
import { Scope } from '../types/scope'

interface IRoleScopesMap {
    [key: string]: Scope[]
}

export const RoleScopesMap: IRoleScopesMap = {
    [Role.viewer]: [Scope.canView],
    [Role.editor]: [Scope.canView, Scope.canEdit],
    [Role.owner]: [
        Scope.canView,
        Scope.canEdit,
        Scope.canCreate,
        Scope.canDelete,
    ],
}
