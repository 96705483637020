import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring'

export default function Fade({ children, show }: any) {
    const transitions = useTransition(show, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        reverse: show,
        delay: 200,
    })
    return transitions(
        (styles, item) =>
            item && <animated.div style={styles}>{children}</animated.div>
    )
}
