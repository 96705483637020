import { RoleScopesMap } from './maps/permissions'
import { Scope } from './types/scope'
import { useUser } from 'modules/user/context/userContext'

const hasPermission = (userScopes: Scope[], requiredScopes: Scope[]) => {
    return userScopes.some((userScope) => {
        return requiredScopes.includes(userScope)
    })
}

export default function PermissionsGate({
    children,
    requiredScopes,
}: {
    children: JSX.Element | JSX.Element[]
    requiredScopes: Scope[]
}) {
    const { user } = useUser()

    return <>{children}</>

    //TODO: fix permissions

    if (!user) {
        return null
    }

    const userScopes = RoleScopesMap[user.role]

    const permissionGranted = hasPermission(userScopes, requiredScopes)

    if (!permissionGranted) return <>No access</>

    return <>{children}</>
}
