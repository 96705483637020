import React from 'react'
import styles from './styles.module.scss'

interface IProps {
    children?: JSX.Element[]
    highlight?: boolean
    main?: boolean
    title?: string
}
//is-flex is-align-items-center is-justify-content-space-between
const Card = ({ children, highlight = false, main = false, title }: IProps) => {
    return (
        <div
            className={`${styles.card} ${highlight ? styles.highlight : ''} ${
                main ? styles.main : ''
            }  `}
        >
            {title && <div className={styles.title}>{title}</div>}
            {children}
        </div>
    )
}

export default Card
