import Algolia from './Algolia'
import { useState } from 'react'
import styles from './styles.module.scss'

export default function Search() {
    const [isSearchOpen, setSearchOpen] = useState()

    const handleClick = (e) => {
        // setSearchTerm('e.target.value')
    }

    return (
        <div onClick={handleClick}>
            <input type="search" className={styles.searchBar} placeholder="Search" onClick={() => setSearchOpen(true)} />
            {isSearchOpen && <Algolia isSearchOpen={isSearchOpen} setSearchOpen={setSearchOpen} />}
        </div>
    )
}
